import React, {Component} from 'react';
import Bimage from './img/greenrock.jpg';
import './styles/Intro.css';

const headStyles = {
    backgroundImage: 'url(' + require('./img/greenrock.jpg')+')',
    height:'100vh',
    backgroundSize:'cover'
}

class Intro extends Component{
    render(){
        return(
            
            <header class="quote" style={headStyles}>
            <h1>22 Years later</h1>
            <p>...and counting. From C to Javascript, many frameworks and an incredible evolution of coding</p>
            <p></p>
            </header>
            
            
        );
    }
}

export default Intro;
import React from 'react'
import './styles/CssMove.css'
function CssMove() {
    return (
        <div className="bg">
           <h1 className="txt">Beautiful Dusk</h1> 
        </div>
    )
}

export default CssMove

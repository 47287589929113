import React, { Component } from 'react';
import ReactDom from 'react-dom';
import './styles/index.css';
import Navigation from './navigation';
import Header from './Header';
import Intro from './intro';
import RProjects from './RProjects';
import MyApi from './MyApi';
import MyIframe from './MyIframe';
import HookCounter from './components/HookStateEx';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Test from './components/test';
import MyMap from './components/MyMap';
import ExchangeRate from './ExchangeRate'
import CssMove from './CssMove'
import chart from './components/chart'

class App extends Component{
    render(){
        return(
            
            <Router>
                <div>
                
                    <Navigation logoTitle='Portfolio'/>
                    <Route exact strict path="/" component={HookCounter}/>
                    <Route exact strict path="/" component={Test}/>
                    <Route exact strict path="/" component={MyMap}/>
                    
                    <Route path="/Header" component={Header}/>
                    <Route path="/Intro" component={Intro}/>
                    <Route path="/RProjects" component={RProjects}/>
                    <Route path="/MyApi" component={MyApi}/>
                    <Route path="/MyIframe" component={MyIframe}/>
                    <Route path="/ExchangeRate" component={ExchangeRate}/>
                    <Route path="/CssMove" component={CssMove}/>
                    <Route path='/chart' component={chart}/>
                    
                    
                </div>
            </Router>
           
        );
    }
}


ReactDom.render(<App />, document.getElementById('root'));



import React from 'react';
import ReactDOM from 'react-dom';
import MyProps from './MyProps';

const name = "John";

const Test =()=>{
    //using props
    return(
    <div>
        <MyProps name="Google" type="Search engine" url="https://www.google.com"/>
        <MyProps name="Vale of Llangollen Boat" type="Canal boat " url="https://www.vlcbt.org.uk"/>
        <MyProps name="Facebook" type="Social media" url="https://www.facebook.com"/>    
    </div>
    )
}

ReactDOM.render(
    <Test />,
    document.getElementById('root')
)
export default Test
import React, { Component } from 'react'
import { Bar, Doughnut } from 'react-chartjs-2';
import {mydata, mylabels,mybgcolor} from '../data/testd'

class chart extends Component {
    constructor(props) {
        super(props);
        this.state={
            
            chartData:{
                labels:mylabels,
                datasets:[{
                    
                    label:'Training frequency of body parts in 5.5 months /23 weeks. Approx 85 days no workout which leaves 76 days of training in 161 days available',
                    data:mydata,
                    backgroundColor:mybgcolor
                }]
            }
        }

        
    }
    render() {
        return (
            <div className="chart">
                <p>The chart below shows recorded data for weight training sessions over a 5.5 month period. Although the frequency of training appears low I did train 2 to 3 times whilst on a 2 week holiday. Additionaly I was sick for quite some time between September and the end of November.</p>
                <Bar
                    
                    data={this.state.chartData}
                    width={50}
                    height={550}
                    options={{ maintainAspectRatio: false,
                        layout: {
                            title:{
                                fontSize:24
                            },
                            legend: {
                                display: true,
                                fontSize:24,
                                labels: {
                                    fontColor: 'rgb(255, 99, 132)'
                                }
                            },
                            padding: {
                                top: 20,
                                left: 135,
                                right: 135,
                                bottom: 185
                            }
                        }
                    
                    }}
                    
                                        
                />
                <h1></h1>
            </div>
        )
    }
}


export default chart


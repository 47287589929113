import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';


import './styles/navigation.css';

const linkStyles = {
    color: "white",
    padding: "10px",
    fontSize: "18px"


};
class Navigation extends Component{
    render(){
        const sections = ['Home','About','Projects','Services','Contact'];
        const navLinks = sections.map(section =>{
            return(
                <li><a href={'#' + section}>{section}</a></li>
            )
        });
        return(
            <nav>
                <h3 class="logo">{this.props.logoTitle}</h3>
                
                <ul>
                    <Link style={linkStyles} to='/intro'><li>Intro</li></Link>
                    <Link style={linkStyles} to='/header'><li>Header</li></Link>
                    <Link style={linkStyles} to='/RProjects'><li>Recent Projects</li></Link>
                    <Link style={linkStyles} to='/MyApi'><li>My API</li></Link>
                    <Link style={linkStyles} to='/MyIframe'><li>Iframe demo</li></Link>
                    <Link style={linkStyles} to='/ExchangeRate'><li>Rates</li></Link>
                    <Link style={linkStyles} to='/CssMove'><li>Css move</li></Link>
                    <Link style={linkStyles} to='/chart'><li>Chart</li></Link>
                </ul>
            </nav>

        );
    }
}

export default Navigation;
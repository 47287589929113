import React from 'react'
import Employees from './data.js'


function MyMap()
{
    const EmpComponents = Employees.map(userId =>{
    return(
         <div> first name={userId.firstName} Job Title={userId.jobTitle}</div>)
    })


    return(
        <div>
            <p>display workers</p>
            {EmpComponents}
        </div>   
    )

}

export default MyMap
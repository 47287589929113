import React from 'react'

const MyProps =(props)=>{
    return(
        <div>
            <h1>{props.name}</h1>
            <h1>{props.type}</h1>
            <a href={props.url}>{props.url}</a>
        </div>
    )
}

export default MyProps
import React, {useEffect, Component} from 'react';

import Iframe from 'react-iframe';
import './styles/MyIframe.css';




class MyIframe extends Component{
    render(){
        
        return(
            <div>
                <div className="youtubeplayer">
                    <Iframe url="https://www.youtube.com/embed/6xrJy-1_qS4" 
                    frameborder="0" 
                    allowfullscreen
                    width="50%"
                    height="100%"
                    />
                    <Iframe url="https://www.youtube.com/embed/qgylp3Td1Bw" 
                    frameborder="0" 
                    allowfullscreen
                    width="50%"            
                    />
                </div>

                <div className="youtubeplayer">
                    
                    
                    
                
                    <Iframe url="https://www.youtube.com/embed/EEIk7gwjgIM" 
                    frameborder="0" 
                    allowfullscreen
                    width="50%"
                    />
                </div>


            </div>
        );
    }
}

export default MyIframe
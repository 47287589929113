import React, {useState, useEffect} from 'react'

function HookCounter() {
    const[count, setCount]= useState(0)
    const inc = 3;
    let randomColor = Math.floor(Math.random()*16777215).toString(16);
    
    useEffect(() =>{
        document.title = 'you clicked '+count+ ' times' 
        document.body.style.backgroundColor = randomColor
    })

    return (
        <div>
            <p>This is an example of useEffect</p>
            <button onClick={()=>setCount(count+1)}>Click me {count}</button>
        </div>
    )
}

export default HookCounter

import React, { useEffect, useState, Component } from "react";
import './styles/MyApi.css';

function MyApi(){
    const [movie, setMovie] = useState("it")
    const [items, setItems] = useState([])
    let mname = "https://www.omdbapi.com/?apikey=977e37bf&t="+movie+ "&plot=full";


    

    useEffect(()=>{
        fetch(mname)
        .then(res=> res.json())
        .then(json=>{             
            setItems(json)
            console.log("this is the url string:"+mname +"these are your items" + JSON.stringify(json))
            })
        },[movie])
        
        return(
            <div className="MyApi">                   
                <form>
                    Input the name of your movie and double click<input onDoubleClick={e => setMovie(e.target.value)}></input>
                    
                </form>
                
                <h1>Movie information</h1>
                <div>    
                    <h2>Title</h2>{items.Title}
                    <p>Year of release- {items.Year}</p>
                    <h2>Plot</h2><p>{items.Plot}</p> 
                    <img src={items.Poster}/>                 
                </div>           
            </div>
            )
    


}      
        
        
 export default MyApi;

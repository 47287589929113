import React, {Component} from 'react';
import Bimage from './img/lake.jpg';
import './styles/Header.css';
const headStyles = {
    backgroundImage: 'url(' + require('./img/lake.jpg')+')',
    height:'100vh',
    backgroundSize:'cover'
}

class Header extends Component{
    render(){
        return(
            <div>
            <header class="quote" style={headStyles}>
            <h1>My Journey</h1>
            <p>The uncontrollable desire to learn more</p>
            </header>
            
            </div>
        );
    }
}

export default Header;
import React, { Component, useEffect, useState } from "react";
import axios from 'axios'


function ExchangeRate(){    
    const [items1, setitems1] = useState([])
    let listItems = [1,2,3,4,5,6,7,8,9]
    let rates = []   
    let i=0
    
//https://openexchangerates.org/api/latest.json?app_id=27fe621bef3148b6ac55c7a4004083f9
    useEffect(()=>{
        fetch('https://openexchangerates.org/api/latest.json?app_id=27fe621bef3148b6ac55c7a4004083f9')
            .then(res=> res.json())
            .then(data=> {setitems1([data.base, ...Object.keys(data.rates)])
            
            }) 
        },[])
        
        
    return(
        
       <div>
          
           Array:<div>{items1}</div> 

            {items1.map(()=><option value={items1.rates}>
               {items1.rates}
           </option>
            )}

           

            





           <select>
           
            </select>
        </div>
    )
}
    
export default ExchangeRate;
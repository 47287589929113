const Employees = [
      {
        "userId": "krish",
        "jobTitle": "Developer",
        "firstName": "Krish",
        "lastName": "Lee",
        "employeeCode": "E1",
        "region": "CA",
        "phoneNumber": "123456",
        "emailAddress": "krish.lee@learningcontainer.com"
      },
      {
        "userId": "devid",
        "jobTitle": "Developer",
        "firstName": "David",
        "lastName": "Rome",
        "employeeCode": "E2",
        "region": "CA",
        "phoneNumber": "1111111",
        "emailAddress": "devid.rome@learningcontainer.com"
      },
      {
        "userId": "Tim",
        "jobTitle": "Program Directory",
        "firstName": "tin",
        "lastName": "jonson",
        "employeeCode": "E3",
        "region": "CA",
        "phoneNumber": "2222222",
        "emailAddress": "tin.jonson@learningcontainer.com"
      },
      {
        "userId": "Bob",
        "jobTitle": "toilet cleaner",
        "firstName": "Bob",
        "lastName": "jonson",
        "employeeCode": "E3",
        "region": "CA",
        "phoneNumber": "2552222",
        "emailAddress": "bob.jonson@learningcontainer.com"
      }
]
 export default Employees
import React, {Component} from 'react';
import Bimage from './img/bridge.jpg';
import './styles/RProjects.css';

const headStyles = {
    backgroundImage: 'url(' + require('./img/bridge.jpg')+')',
    height:'100vh',
    backgroundSize:'cover'
}

class RProjects extends Component{
    render(){
        return(
            
            <header class="RProjects " style={headStyles} >
            <h3>Recent Projects</h3>
            <div className="row">
                <Icons myIcon={iconObj[0].icon} myTitle={iconObj[0].title} myDescription={iconObj[0].description} />  
                <Icons myIcon={iconObj[1].icon} myTitle={iconObj[1].title} myDescription={iconObj[1].description} />
                <Icons myIcon={iconObj[2].icon} myTitle={iconObj[2].title} myDescription={iconObj[2].description} />
                
            </div>
                   
            </header>
            
           
        );
    }
}

const iconObj=[
    {
        icon:<ion-icon name="logo-css3"></ion-icon>,
        title:'THREEJS',
        description: 'Star Rotation and zoom'
    },
    {
        icon:<ion-icon name="logo-react"></ion-icon>,
        title:'REACT',
        description: 'This website for now!'
    },
    {
        icon:<ion-icon name="logo-wordpress"></ion-icon>,
        title:'WORDPRESS',
        description: 'Canal Boat Trust Website'
    }
]
class Icons extends Component{
    render(){
        return(
            <div>
                <span>{this.props.myIcon}</span>
                <h4>{this.props.myTitle}</h4>
                <p>{this.props.myDescription}</p>
            </div>
        );
    }
}
export default RProjects;